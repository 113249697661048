import { Injectable } from '@angular/core';
import { CanActivate} from '@angular/router';
import { Router } from '@angular/router';
import { map} from 'rxjs/operators';
import Swal from "sweetalert2";
import { ApiService } from '../services/api.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private api: ApiService) {}
  canActivate(){
    return this.api.apiRequest("GET","/token?token="+localStorage.getItem("token"),null).pipe(map(res => {
      if(res.head.error == 0) return true;
      Swal.fire({
        title: 'ERROR!',
        text: res.head.message,
        timer: 2000,
        allowOutsideClick : false,
        timerProgressBar: true,
        allowEscapeKey: false,
      }).then((()=>{this.router.navigate(["/auth"])}));
      Swal.showLoading();
      return false;
    }));
  }

}