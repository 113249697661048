import { Injectable } from "@angular/core";
import { MenuItem } from "../models/menuItem.model";

@Injectable({providedIn: 'root'})
export class EnvironmentService
{
    constructor(){}
    public serverURL : string = "https://covid.erteknet.hu";
    public menuItems : MenuItem[];
    public company_id : string | null= null;
    public partner_id : string | null = null;
}