import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import { map } from 'rxjs/operators';
import Swal from "sweetalert2";
import { MenuResponse } from '../models/responses/menu.response.model';
import { ApiService } from '../services/api.service';
import { EnvironmentService } from '../services/environment.service';

@Injectable({providedIn: 'root'})
export class CompanyGuard implements CanActivate {
  constructor(private router: Router, private api : ApiService, private env: EnvironmentService) {}
  canActivate(route: ActivatedRouteSnapshot){
    this.api.apiRequest<MenuResponse>("GET", "/company/" + route.paramMap.get("id") + "/menu?token=" + localStorage.getItem("token")).subscribe(res => {
      this.env.menuItems = res.body;
    });
    
    return this.api.apiRequest("GET","/company/" + route.paramMap.get("id") + "?token=" + localStorage.getItem("token")).pipe(map(res=>{
        if(res.head.error == 0)
        {
          this.env.company_id = route.paramMap.get("id");
          return true;
        } 
        Swal.fire({
          title: 'ERROR!',
          text: res.head.message,
          timer: 2000,
          allowOutsideClick : false,
          timerProgressBar: true,
          allowEscapeKey: false,
        }).then((()=>{this.router.navigate(["/companies"])}));
        Swal.showLoading();
        return false;
    }));
  }
}