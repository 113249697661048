import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EPMResponse } from "../models/responses/response.model";
import { EnvironmentService } from "./environment.service";
import Swal from "sweetalert2";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";


@Injectable({providedIn: 'root'})
export class ApiService
{
    constructor(private http: HttpClient, private env: EnvironmentService, private router: Router){}
    apiRequest<Response extends EPMResponse>(method: string, uri: string, body : any = null)
    {
        let sub: Observable<Response> = new Observable<Response>();
        try {
            switch(method)
        {
            case "GET":
                sub = this.http.get<Response>(this.env.serverURL + uri);
                break;
            case "POST":
                sub = this.http.post<Response>(this.env.serverURL + uri, body);
                break;
            case "DELETE":
                sub = this.http.delete<Response>(this.env.serverURL + uri);
                break;
            case "PUT":
                sub = this.http.put<Response>(this.env.serverURL + uri, body);
                break;
            default:
                throw new Error("Invalid method: " + method);
                break;
        }
        } catch (error) {
            Swal.fire({
                title: 'ERROR!',
                text: error,
                timer: 2000,
                allowOutsideClick : false,
                timerProgressBar: true,
                allowEscapeKey: false,
              });
              Swal.showLoading();
              sub = new Observable<Response>();
        } finally{
            return sub.pipe(map(res => {
                if (res.head.error)
                {
                    let msg =typeof res.head.error == "string" ? res.head.error : JSON.stringify(res.head.error);
                    Swal.fire({
                        title: 'ERROR!',
                        text: res.head.message + "-" + res.head.error,
                        timer: 2000,
                        allowOutsideClick : false,
                        timerProgressBar: true,
                        allowEscapeKey: false,
                      }).then(()=>{
                          console.log("BASD");
                          if([104,105,120].indexOf(res.head.error) > -1){
                              console.log("ASD");
                              this.router.navigate(["/auth/login"]);
                          }
                            
                      });
                      Swal.showLoading();
                }
                return res;
            }));
        }
    }

    download(url: string) : Observable<Blob>
    {
        return this.http.get(url,{
            responseType: 'blob'
          })
    }

}